import { Fruit } from '../object/Fruit';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { GameResult } from '../GameResult';
import { useEffect, useState } from 'react';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CloseButton from 'react-bootstrap/CloseButton';

const cx = classNames.bind(styles);

interface IntroProps {
  isVisible: boolean;
  loadedUserInfo: boolean;
  handleGameStart: () => void;
  handleShowRankModal: () => void;
}

const userBrowser = ((agent) => {
  switch (true) {
    case agent.indexOf('edge') > -1:
      return 'MS Edge (EdgeHtml)';
    case agent.indexOf('edg') > -1:
      return 'MS Edge Chromium';
    case agent.indexOf('samsungbrowser') > -1:
      return 'samsung browser';
    case agent.indexOf('opr') > -1 && !!(window as any).opr:
      return 'opera';
    case agent.indexOf('chrome') > -1 && !!(window as any).chrome:
      return 'chrome';
    case agent.indexOf('trident') > -1:
      return 'Internet Explorer';
    case agent.indexOf('firefox') > -1:
      return 'firefox';
    case agent.indexOf('safari') > -1:
      return 'safari';
    default:
      return 'other';
  }
})(window.navigator.userAgent.toLowerCase());

const Intro = ({ isVisible, loadedUserInfo, handleGameStart, handleShowRankModal }: IntroProps) => {
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  const [isShowPWACard, setIsShowPWACard] = useState<boolean>(true);
  //   const [isShowOpenWithChrome, setIsShowOpenWithChrome] = useState<boolean>(true);

  const handleBeforeInstallPrompt = (event: any) => {
    event.preventDefault();

    setDeferredPrompt(event);
    setIsShowPWACard(true);
  };

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const positionCircularly = (totalElements: number, index: number) => {
    // Radius of circle around start button
    const radius = 150;
    const angle = (2 * Math.PI * index) / totalElements;

    const x = radius * Math.cos(angle);
    const y = radius * Math.sin(angle);

    return {
      top: `calc(50% + ${y}px - 24px)`,
      left: `calc(50% + ${x}px - 24px)`,
    };
  };

  const fruitItemEls = Object.values(Fruit).map((fruit, index) => {
    // Object.keys(Fruit).length
    const itemPositions = positionCircularly(20, index);
    // if fruit = '08_suika_savannah'
    let fruitPath = fruit.valueOf();

    if (fruitPath === '08_suika_savannah') {
      fruitPath = '08_suika_peach';
    }

    return (
      <li
        key={fruitPath}
        className={cx('listItem')}
        style={{
          backgroundImage: `url(${require('../../../resource/' + fruitPath + '.png')})`,
          top: itemPositions.top,
          left: itemPositions.left,
        }}
      />
    );
  });

  const onClick = async () => {
    // if (!window.navigator.userAgent.toLowerCase().includes('kakaotalk')) {
    //   window.addEventListener('beforeunload', (ev) => {
    //     ev.preventDefault();
    //     return (ev.returnValue = 'Are you sure you want to close?');
    //   });
    // }

    handleGameStart();
  };

  if (!isVisible) return null;

  let userName = 'Loading...';

  if (loadedUserInfo) {
    userName = GameResult.userName;
  } else if (GameResult.isNewUser()) {
    userName = 'Welcome';
  }

  const handleInstall = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();

      deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          handlePWACardClose();
          //   gtag('event', 'PWAAccepted', {});
        } else {
          //   gtag('event', 'PWARejected', {});
        }

        setDeferredPrompt(null);
      });
    }
  };

  const handlePWACardClose = () => {
    setIsShowPWACard(false);
  };

  //   const handleOpenWithChromeClose = () => {
  //     setIsShowOpenWithChrome(false);
  //   };

  function isStandalone() {
    return !!(navigator as any).standalone || window.matchMedia('(display-mode: standalone)').matches;
  }

  const notSupportPWA = () => {
    return (
      userBrowser !== 'chrome' && (window.navigator as any).userAgentData?.platform === 'Android' && !isStandalone()
    );
  };

  return (
    <div className={cx('introArea')}>
      <ul className={cx('listWrap')}>{fruitItemEls}</ul>

      <div className={cx('titleArea')}>
        <button className={cx('btn')} onClick={onClick}>
          Sheesh Bruh
        </button>
      </div>

      <ToastContainer
        position="bottom-center"
        className="p-3"
        style={{ zIndex: 1, pointerEvents: 'auto', display: isShowPWACard ? 'block' : 'none' }}
      >
        <Card>
          {/* <Card.Header>Featured</Card.Header> */}
          <Card.Body>
            {/* <div className='' style={{display: "flex"}}>
              <img src="/maskable_icon_x72.png" className="rounded mb-2 me-2" alt="" style={{width: "15%"}}/>
              <div className='d-flex justify-content-between align-items-center' style={{flex: "auto"}}>
                <Card.Title>
                      Suika with Bomb
                </Card.Title>
                <CloseButton style={{marginBottom: "0.5rem"}} onClick={handlePWACardClose}/>
              </div>
              </div>
            <Card.Text style={{marginBottom: "1rem"}}>
              </Card.Text>
            <Button variant="primary" style={{paddingLeft: "1.5rem", paddingRight: "1.5rem"}} onClick={handleInstall}>홈 화면에 추가</Button> */}
            <Button variant="primary" style={{ paddingLeft: '1.5rem', paddingRight: '1.5rem' }} onClick={handleInstall}>
              Install
            </Button>
          </Card.Body>
        </Card>
      </ToastContainer>

      <button className={`${cx('rank-btn')}`} onClick={handleShowRankModal}>
        {userName}
      </button>
    </div>
  );
};

export default Intro;
