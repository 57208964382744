import { getRenderWidth } from './Size';

export enum SpecialItem {
  BOMB = 'BOMB',
}

export enum Fruit {
  //   BLUEBERRY = "01_BLUEBERRY", // 250x250
  //   STRAWBERRY = "02_STRAWBERRY",
  //   TANGERINE = "03_TANGERINE",
  //   TOMATO = "04_TOMATO",
  //   AVOCADO = "05_AVOCADO",
  //   KOREANMELON = "06_KOREANMELON",
  //   APPLE = "07_APPLE",
  //   PEACH = "08_PEACH",
  //   COCONUT = "09_COCONUT",
  //   MELON = "10_MELON",
  //   WATERMELON = "11_WATERMELON",
  //   GOLDWATERMELON = "12_GOLDWATERMELON",
  BLUEBERRY = '01_suika_cherry', // 192x246
  STRAWBERRY = '02_suika_strawberry', // 213x236
  TANGERINE = '03_suika_grapes', // 322x340
  TOMATO = '04_suika_dekopon',
  AVOCADO = '05_suika_orange',
  KOREANMELON = '06_suika_apple',
  APPLE = '07_suika_pear',
  PEACH = '08_suika_savannah',
  COCONUT = '09_suika_pineapple',
  MELON = '10_suika_melon',
  WATERMELON = '11_suika_watermelon', // 402x402
  GOLDWATERMELON = '12_GOLDWATERMELON',

  // find ./ -iname '*_suika_*.png' -exec magick convert \{} -verbose -set filename:base "%[basename]"
  //        -background none -gravity center -resize '400x400^' -extent '1:1#'  "./out/%[filename:base].png" \;

  // find ./original -iname '*_suika_*.png' -exec magick convert \{} -verbose -set filename:base "%[basename]" -background none -gravity center -resize '250x250^' -extent '250:250#'  "./250/%[filename:base].png" \;
}

export type FruitType = keyof typeof Fruit;
export type ItemType = SpecialItem | Fruit;

let itemSizeWeight = 0.91;

export function setItemSizeWeight(num: number) {
  itemSizeWeight = num;
}

let first_fruit = true;

export const getSpecialItemFeature = (item: ItemType) => {
  switch (item) {
    case SpecialItem.BOMB:
      return { radius: (getRenderWidth() / 30) * itemSizeWeight, mass: 1, label: SpecialItem.BOMB, score: null };
    default:
      return null;
  }
};


export const getFruitFeature = (fruit: ItemType) => {
  // console.log('here');
  switch (fruit) {
    case Fruit.BLUEBERRY:
      //   return { radius: (getRenderWidth() / 24) * itemSizeWeight, mass: 0.8, label: Fruit.BLUEBERRY, score: 1 };
      return { radius: (getRenderWidth() / 24) * itemSizeWeight, mass: 1, label: Fruit.BLUEBERRY, score: 1 };
    case Fruit.STRAWBERRY:
      return { radius: (getRenderWidth() / 18) * itemSizeWeight, mass: 1, label: Fruit.STRAWBERRY, score: 3 };
    case Fruit.TANGERINE:
      return { radius: (getRenderWidth() / 12) * itemSizeWeight, mass: 1, label: Fruit.TANGERINE, score: 6 };
    case Fruit.TOMATO:
      return { radius: (getRenderWidth() / 10) * itemSizeWeight, mass: 1, label: Fruit.TOMATO, score: 10 };
    case Fruit.AVOCADO:
      return { radius: (getRenderWidth() / 8) * itemSizeWeight, mass: 1, label: Fruit.AVOCADO, score: 15 };
    case Fruit.KOREANMELON:
      return { radius: (getRenderWidth() / 7) * itemSizeWeight, mass: 1, label: Fruit.KOREANMELON, score: 21 };
    case Fruit.APPLE:
      return { radius: (getRenderWidth() / 6) * itemSizeWeight, mass: 1, label: Fruit.APPLE, score: 28 };
    case Fruit.PEACH:
      return { radius: (getRenderWidth() / 5.3) * itemSizeWeight, mass: 1, label: Fruit.PEACH, score: 36 };
    case Fruit.COCONUT:
      return { radius: (getRenderWidth() / 4.6) * itemSizeWeight, mass: 1, label: Fruit.COCONUT, score: 45 };
    case Fruit.MELON:
      return { radius: (getRenderWidth() / 3.95) * itemSizeWeight, mass: 1, label: Fruit.MELON, score: 55 };
    case Fruit.WATERMELON:
      return { radius: (getRenderWidth() / 3.5) * itemSizeWeight, mass: 1, label: Fruit.WATERMELON, score: 66 };
    case Fruit.GOLDWATERMELON:
      return { radius: (getRenderWidth() / 3.5) * itemSizeWeight, mass: 1, label: Fruit.GOLDWATERMELON, score: 78 };
    default:
      return null;
  }
};

export const getItemTypeFeature = (item: ItemType) => {
  return getFruitFeature(item) || getSpecialItemFeature(item);
};

export const getRandomFruitFeature = (exclude_bomb = false) => {
  // if (first_fruit) {
  //   first_fruit = false;
  //   return getFruitFeature(Fruit.MELON);
  // }
  // else {
  //   return getFruitFeature(Fruit.MELON);
  //   // return getSpecialItemFeature(SpecialItem.BOMB);
  // }
  if (first_fruit) {
    first_fruit = false;
    exclude_bomb = true;
  }

  // Percent chance of getting a bomb
  if (Math.random() < 0.98 || exclude_bomb) {
    // const fruits = Object.values(Fruit).slice(0, 5);

    // Selection of items to start with
    const fruits = Object.values(Fruit).slice(0, 5);

    const randomIndex = Math.floor(Math.random() * fruits.length); // 무작위 인덱스 선택
    return getFruitFeature(fruits[randomIndex]);
  } else {
    return getSpecialItemFeature(SpecialItem.BOMB);
  }
};

export const getNextFruitFeature = (currentFruit: Fruit) => {
  // Current fruit index
  const currentIndex = Object.values(Fruit).indexOf(currentFruit);

  if (currentIndex === -1) {
    // Error case
    return null;
  }

  // Get next type of fruit
  const nextIndex = (currentIndex + 1) % Object.values(Fruit).length;

  // Select next
  const nextFruit = Object.values(Fruit)[nextIndex];

  // Properties of next
  const feature = getFruitFeature(nextFruit);

  return feature;
};
